import repository from './repository'

const resource = 'pt-cash-vouchers'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  edit (id) {
    return repository.get(`${resource}/${id}`)
  },
  show (id) {
    return repository.get(`${resource}/${id}/show`)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  approve (id) {
    return repository.post(`${resource}/${id}/approve`)
  },
  reject (id) {
    return repository.post(`${resource}/${id}/reject`)
  },
  pdf (id) {
    const conf = {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'text',
    }
    return repository.get(`${resource}/${id}/pdf`, conf)
  },
}
