<template lang="pug">
  div
    base-snackbar(
      :errors.sync="ptCashVoucherGetErrors"
    )
    v-data-table(
      :items="ptCashVoucherList"
      :headers="headers"
      :page.sync="page"
      :itemsPerPage.sync="itemsPerPage"
      :server-items-length="ptCashVoucherGetCount"
      :loading="ptCashVoucherGetting"
      single-expand
      dense
    )
      template(v-slot:item="{ item, isExpanded, expand }")
        tr
          td
            v-btn(
              text
              color="primary"
              x-small
              @click="$emit('show', item.id)"
            )
              v-icon mdi-information-outline
            span {{ item.pt_cash_voucher_number }}
          td {{ item.pay_to }}
          td {{ item.date.dateFormat() }}
          td {{ item.requestor }}
          td.text-right {{ (item.amount || 0).phpFormat() }}
          td.text-center {{ item.status ? item.status.description : null }}
          td.text-center
            v-btn(
              v-if="!item.approved_rejected_by && allowedForApproval"
              color="success"
              text
              small
              dense
              @click="approvePtCash(item.id)"
              :loading="ptCashVoucherApprovePosting"
            )
              span Approve
            v-btn(
              v-if="!item.approved_rejected_by && allowedForApproval"
              color="error"
              text
              small
              dense
              @click="rejectPtCash(item.id)"
              :loading="ptCashVoucherRejectPosting"
            )
              span Reject
            span(
              v-else
              :class="approvedRejectedColor(item.status)"
            ) {{ item.approved_rejected_by ? item.approved_rejected_by.name : null }}
          td.text-left
            v-icon(
              color="blue-grey darken-2"
              :class="!isExpanded || 'is-expanded'"
              @click="expand(!isExpanded)"
            ) mdi-chevron-down
            v-icon(
              v-if="item.pt_cash_voucher_status_id !== 'AP'"
              color="yellow"
              @click="$router.push(`/vouchers/${item.voucher_id}/petty-cash/${item.id}`)"
            ) mdi-pencil
            v-icon(
              v-if="item.pt_cash_voucher_status_id === 'NW'"
              color="red"
              @click="printPdf(item.id)"
            ) mdi-file
      template(v-slot:expanded-item="{ item }")
        tr
          td(colspan="9")
            v-row(justify="center")
              v-col(cols="9")
                v-simple-table(dense)
                  thead
                    tr
                      th Account Code
                      th Description
                      th.text-right Amount
                  tbody
                    tr(v-for="detail in item.details" :key="detail.id")
                      td {{ detail.account_code.account_code }}
                      td {{ detail.description }}
                      td.text-right {{ detail.amount.phpFormat() }}
                    tr
                      th(colspan="2") Total
                      td.text-right {{ totalAmount(item.details).phpFormat() }}
            //- v-btn(
            //-   color="yellow darken-2"
            //-   small
            //-   dark
            //-   text
            //-   @click="$router.push({ name: 'vouchers.petty-cash.edit', params: { voucherId: $route.params.voucherId, ptCashVoucherId: item.id }})"
            //- )
            //-   v-icon mdi-pencil
</template>
<style lang="sass">
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
import ptCashVoucherRepository from '@/repositories/pt-cash-voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [ptCashVoucherGetVars, ptCashVoucherGetVarNames] = requestVars({ identifier: 'pt-cash-voucher', pagination: true })
const [ptCashVoucherRejectVars, ptCashVoucherRejectVarNames] = requestVars({ identifier: 'pt-cash-voucher-reject', hasData: false, request: 'post' })
const [ptCashVoucherApproveVars, ptCashVoucherApproveVarNames] = requestVars({ identifier: 'pt-cash-voucher-approve', hasData: false, request: 'post' })

const ptCashVoucherGetHandler = new VueRequestHandler(null, ptCashVoucherGetVarNames, {}, true)
const ptCashVoucherRejectHandler = new VueRequestHandler(null, ptCashVoucherRejectVarNames)
const ptCashVoucherApproveHandler = new VueRequestHandler(null, ptCashVoucherApproveVarNames)

const tableVars = () => ({
  headers: [
    { text: 'PTC Voucher Id', value: 'id' },
    { text: 'Pay To', value: 'pay_to' },
    { text: 'Date', value: 'date' },
    { text: 'Requestor', value: 'requestor' },
    { text: 'Disbursed Amt.', value: 'amount' },
    { text: 'Status', value: 'pt_cash_voucher_status_id', align: 'center' },
    { text: 'Approved / Rejected By', value: 'approved_rejected_by', sortable: false, align: 'center' },
    { text: 'Config', sortable: false, align: 'center' },
  ],
  page: 1,
  itemsPerPage: 10,
})

export default {
  name: 'TablePtCashVoucher',
  data () {
    return {
      ...ptCashVoucherGetVars,
      ...tableVars(),
      ...ptCashVoucherRejectVars,
      ...ptCashVoucherApproveVars,
    }
  },
  computed: {
    allowedForApproval () {
      return this.$store.state.auth.accessLevel > 5
    },
  },
  created () {
    this.initWebsockets()
    this.getPtCashVoucher()
  },
  watch: {
    '$route.params.voucherId': function () {
      this.getPtCashVoucher()
    },
    itemsPerPage () {
      this.getPtCashVoucher()
    },
    page () {
      this.getPtCashVoucher()
    },
  },
  methods: {
    totalAmount (details) {
      return details.reduce((total, item) => total + item.amount, 0)
    },
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      const empty = (obj, m) => this.$objectEmpty(obj) || !m
      echo.private('database.event')
        .listen('DBStoreEvent', ({ data, model }) => {
          if (empty(data, model)) return
          if (model === 'PtCashVoucher') {
            this.ptCashVoucherList.push(data)
          }
        })
        .listen('DBUpdateEvent', ({ data, model }) => {
          if (empty(data, model)) return
          if (model === 'PtCashVoucher') {
            this.ptCashVoucherList = this.ptCashVoucherList.map(item => {
              if (item.id === data.id) return Object.assign({ ...item, ...data })
              return Object.assign({ ...item })
            })
          }
        })
    },
    getPtCashVoucher () {
      const handler = ptCashVoucherGetHandler
      const repository = ptCashVoucherRepository.index
      const params = this.getPtCashVoucherParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getPtCashVoucherParams () {
      const voucherId = this.$route.params.voucherId || null
      const params = {
        voucherId,
        paginate: true,
        with: 'status,approvedRejectedBy,details.accountCode',
        limit: this.itemsPerPage,
        page: this.page,
      }
      return Object.keys(params)
        .reduce((result, key) => {
          if (!params[key]) return result
          result[key] = params[key]
          return result
        }, {})
    },
    approvePtCash (id) {
      this.$confirm('Are you sure you want to approve this pt cash?', { title: 'Approve?', icon: 'mdi-check', color: 'green' })
        .then(confirm => {
          if (!confirm) return
          const handler = ptCashVoucherApproveHandler
          const repository = ptCashVoucherRepository.approve
          handler.setVue(this)
          handler.execute(repository, [id])
        })
    },
    rejectPtCash (id) {
      this.$confirm('Are you sure you want to reject this pt cash?', { title: 'Reject?', icon: 'mdi-alert', color: 'error' })
        .then(confirm => {
          if (!confirm) return
          const handler = ptCashVoucherRejectHandler
          const repository = ptCashVoucherRepository.reject
          handler.setVue(this)
          handler.execute(repository, [id])
        })
    },
    approvedRejectedColor (args) {
      const { id = null } = args || {}
      if (id === 'AP') return 'green--text'
      if (id === 'RJ') return 'red--text'
      return null
    },
    async printPdf (id) {
      this.$swal('loading', {
        config: {
          loading: {
            text: 'Generating...',
          },
        },
      })
      try {
        const { data } = await ptCashVoucherRepository.pdf(id)
        await this.$swal('success', { config: { title: { text: 'Pdf Generated' } } })
        Window.openPdf(data)
      } catch (e) {
        this.$swal('error', {
          config: {
            title: {
              text: e.message,
            },
          },
        })
      }
    },
  },
}
</script>